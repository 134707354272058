import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { DoDontContainer, Do, Dont, Caption } from '@primer/gatsby-theme-doctocat';
import ScrollToAnchor from '~/src/components/scroll-to-anchor';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ScrollToAnchor mdxType="ScrollToAnchor" />
    <h2>{`Syntax`}</h2>
    <p>{`We show meaning or objects through syntax such as angled brackets, square brackets, curly brackets, parenthesis, and color.`}</p>
    <h3>{`Branches`}</h3>
    <p>{`Display branch names in brackets and/or cyan`}</p>
    <img src="https://user-images.githubusercontent.com/980622/215497467-7cf94a73-4b35-4352-81a4-a6c00d1f3486.png" alt="A branch name in brackets and cyan" />
    <h3>{`Labels`}</h3>
    <p>{`Display labels in parenthesis and/or gray`}</p>
    <img src="https://user-images.githubusercontent.com/980622/215497505-182904ee-019e-41c9-825f-cf21ecbabdfb.png" alt="A label name in parenthesis and gray" />
    <h3>{`Repository`}</h3>
    <p>{`Display repository names in bold where appropriate`}</p>
    <img src="https://user-images.githubusercontent.com/980622/215497508-3d5f1879-54f0-4c8f-9156-9143e35eec3a.png" alt="A repository name in bold" />
    <h3>{`Help`}</h3>
    <p>{`Use consistent syntax in `}<a parentName="p" {...{
        "href": "https://github.com/cli/cli/blob/trunk/docs/command-line-syntax.md"
      }}>{`help pages`}</a>{` to explain command usage.`}</p>
    <h4>{`Literal text`}</h4>
    <p>{`Use plain text for parts of the command that cannot be changed`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`gh help
`}</code></pre>
    <Caption mdxType="Caption">The argument help is required in this command</Caption>
    <h4>{`Placeholder values`}</h4>
    <p>{`Use angled brackets to represent a value the user must replace. No other expressions can be contained within the angled brackets.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`gh pr view <issue-number>
`}</code></pre>
    <Caption mdxType="Caption">Replace "issue-number" with an issue number</Caption>
    <h4>{`Optional arguments`}</h4>
    <p>{`Place optional arguments in square brackets. Mutually exclusive arguments can be included inside square brackets if they are separated with vertical bars.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`gh pr checkout [--web]
`}</code></pre>
    <Caption mdxType="Caption">The argument `--web` is optional.</Caption>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`gh pr view [<number> | <url>]
`}</code></pre>
    <Caption mdxType="Caption">The "number" and "url" arguments are optional.</Caption>
    <h4>{`Required mutually exclusive arguments`}</h4>
    <p>{`Place required mutually exclusive arguments inside braces, separate arguments with vertical bars.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`gh pr {view | create}
`}</code></pre>
    <h4>{`Repeatable arguments`}</h4>
    <p>{`Ellipsis represent arguments that can appear multiple times`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`gh pr close <pr-number>...
`}</code></pre>
    <h4>{`Variable naming`}</h4>
    <p>{`For multi-word variables use dash-case (all lower case with words separated by dashes)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`gh pr checkout <issue-number>
`}</code></pre>
    <h4>{`Additional examples`}</h4>
    <p>{`Optional argument with placeholder:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`<command> <subcommand> [<arg>]
`}</code></pre>
    <p>{`Required argument with mutually exclusive options:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`<command> <subcommand> {<path> | <string> | literal}
`}</code></pre>
    <p>{`Optional argument with mutually exclusive options:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`<command> <subcommand> [<path> | <string>]
`}</code></pre>
    <h2>{`Prompts`}</h2>
    <p>{`Generally speaking, prompts are the CLI’s version of forms.`}</p>
    <ul>
      <li parentName="ul">{`Use prompts for entering information`}</li>
      <li parentName="ul">{`Use a prompt when user intent is unclear`}</li>
      <li parentName="ul">{`Make sure to provide flags for all prompts`}</li>
    </ul>
    <h3>{`Yes/No`}</h3>
    <p>{`Use for yes/no questions, usually a confirmation. The default (what will happen if you enter nothing and hit enter) is in caps.`}</p>
    <img src="https://user-images.githubusercontent.com/980622/215497458-36deca06-477d-47e6-8656-d83b71edbe82.png" alt="An example of a yes/no prompt." />
    <h3>{`Short text`}</h3>
    <p>{`Use to enter short strings of text. Enter will accept the auto fill if available`}</p>
    <img src="https://user-images.githubusercontent.com/980622/215497453-0d3fc9a0-96e7-4285-8a28-464abad66ad6.png" alt="An example of a short text prompt." />
    <h3>{`Long text`}</h3>
    <p>{`Use to enter large bodies of text. E key will open the user’s preferred editor, and Enter will skip.`}</p>
    <img src="https://user-images.githubusercontent.com/980622/215497445-4397c8e2-5900-4863-b5f7-bb6ceaba34d8.png" alt="An example of a long text prompt." />
    <h3>{`Radio select`}</h3>
    <p>{`Use to select one option`}</p>
    <img src="https://user-images.githubusercontent.com/980622/215497450-589acfd9-f1a4-45da-a9e3-146cc48452a4.png" alt="An example of a radio select prompt" />
    <h3>{`Multi select`}</h3>
    <p>{`Use to select multiple options`}</p>
    <img src="https://user-images.githubusercontent.com/980622/215497447-5a6628d8-339a-479a-8a72-fea5ac78df2a.png" alt="An example of a multi select prompt" />
    <h2>{`State`}</h2>
    <p>{`The CLI reflects how GitHub.com displays state through `}<a parentName="p" {...{
        "href": "/native/cli/foundations#color"
      }}>{`color`}</a>{` and `}<a parentName="p" {...{
        "href": "/native/cli/foundations#iconography"
      }}>{`iconographhy`}</a>{`.`}</p>
    <img src="https://user-images.githubusercontent.com/980622/215497462-02b78963-a977-4801-aef1-c693787aca62.png" alt="A collection of examples of state from various command outputs" />
    <h2>{`Progress indicators`}</h2>
    <p>{`For processes that might take a while, include a progress indicator with context on what’s happening.`}</p>
    <img src="https://user-images.githubusercontent.com/980622/215497460-92b83be7-829a-4b78-adeb-f2d369f95a8a.png" alt="An example of a loading spinner when forking a repository" />
    <h2>{`Headers`}</h2>
    <p>{`When viewing output that could be unclear, headers can quickly set context for what the user is seeing and where they are.`}</p>
    <h3>{`Examples`}</h3>
    <img src="https://user-images.githubusercontent.com/980622/215497394-b2ec59b2-5082-41ee-9ccb-2bbe80f21fe5.png" alt="An example of the header of the gh pr create command" />
    <Caption mdxType="Caption">
  The header of the "gh pr create" command reassures the user that they're creating the correct pull request
    </Caption>
    <img src="https://user-images.githubusercontent.com/980622/215497378-70bbe5b1-74dc-4b95-a329-c8f7e2908bae.png" alt="An example of the header of the gh pr list command" />
    <Caption mdxType="Caption">The header of the "gh pr list" command sets context for what list the user is seeing</Caption>
    <h2>{`Lists`}</h2>
    <p>{`Lists use tables to show information.`}</p>
    <ul>
      <li parentName="ul">{`State is shown in color.`}</li>
      <li parentName="ul">{`A header is used for context.`}</li>
      <li parentName="ul">{`Information shown may be branch names, dates, or what is most relevant in context.`}</li>
    </ul>
    <img src="https://user-images.githubusercontent.com/980622/215497378-70bbe5b1-74dc-4b95-a329-c8f7e2908bae.png" alt="An example of gh pr list" />
    <h2>{`Detail views`}</h2>
    <p>{`Single item views show more detail than list views. The body of the item is rendered indented. The item’s URL is shown at the bottom.`}</p>
    <img src="https://user-images.githubusercontent.com/980622/215497374-4410111c-b2c9-4f04-8bab-1acd7bcd4135.png" alt="An example of gh issue view" />
    <h2>{`Empty states`}</h2>
    <p>{`Make sure to include empty messages in command outputs when appropriate.`}</p>
    <img src="https://user-images.githubusercontent.com/980622/215497357-142ef375-ea50-4156-b00a-82f511935b11.png" alt="The empty state of the gh pr status command" />
    <Caption mdxType="Caption">The empty state of "gh pr status"</Caption>
    <img src="https://user-images.githubusercontent.com/980622/215497363-b43175f4-9b3a-4ad8-8a1e-b9c3bf31e042.png" alt="The empty state of the gh issue list command" />
    <Caption mdxType="Caption">The empty state of "gh issue list"</Caption>
    <h2>{`Help pages`}</h2>
    <p>{`Help commands can exist at any level:`}</p>
    <ul>
      <li parentName="ul">{`Top level (`}<inlineCode parentName="li">{`gh`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`Second level (`}<inlineCode parentName="li">{`gh [command]`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`Third level (`}<inlineCode parentName="li">{`gh [command] [subcommand]`}</inlineCode>{`)`}</li>
    </ul>
    <p>{`Each can be accessed using the `}<inlineCode parentName="p">{`--help`}</inlineCode>{` flag, or using `}<inlineCode parentName="p">{`gh help [command]`}</inlineCode>{`.`}</p>
    <p>{`Each help page includes a combination of different sections.`}</p>
    <h3>{`Required sections`}</h3>
    <ul>
      <li parentName="ul">{`Usage`}</li>
      <li parentName="ul">{`Core commands`}</li>
      <li parentName="ul">{`Flags`}</li>
      <li parentName="ul">{`Learn more`}</li>
      <li parentName="ul">{`Inherited flags`}</li>
    </ul>
    <h3>{`Other available sections`}</h3>
    <ul>
      <li parentName="ul">{`Additional commands`}</li>
      <li parentName="ul">{`Examples`}</li>
      <li parentName="ul">{`Arguments`}</li>
      <li parentName="ul">{`Feedback`}</li>
    </ul>
    <h3>{`Example`}</h3>
    <img src="https://user-images.githubusercontent.com/980622/215497399-8a13ace4-d795-4014-ac23-5ecfd5eac237.png" alt="The output of gh help" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      